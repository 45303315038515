import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const frontmatter = {
  title: 'Setting up your first Mastodon server',
  draft: false,
  date: '2017-08-22T22:58:59.501Z'
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Mastodon is a relatively young social network that aims to be a privacy concious, federated Twitter. Most people opt to join one of the larger servers (`}<a parentName="p" {...{
        "href": "http://tooter.today"
      }}>{`tooter.today`}</a>{` can help find one if you want) but you and I are not most people. We like our privacy and our self hosted services, so, we're going to setup a personal Mastodon server that ticks off all the boxes:`}</p>
    <ul>
      <li parentName="ul">{`Single user`}</li>
      <li parentName="ul">{`SSL protected`}</li>
      <li parentName="ul">{`Email ready`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`This guide assumes some basic knowledge of Linux and DNS, but I'll try to be as descriptive as possible for people who are going into this blind. If you have any questions feel free to contact me!`}</p>
    </blockquote>
    <h2>{`Setting up a VPS`}</h2>
    <p>{`Here I'm going to use Digital Ocean's $10 Droplet, but feel free to substitute it with whever VPS provider you would like as long as the server you choose includes `}<em parentName="p">{`at least`}</em>{` 1 GB of RAM. Thankfully, Digital Ocean provides "One-click Apps" which has decent defaults out of the box for various apps. For us, we'll select one that has Docker built in on top of Ubuntu 16.04 LTS to speed things up but you can follow `}<a parentName="p" {...{
        "href": "https://docs.docker.com/compose/install/"
      }}>{`the official docs`}</a>{` to get yourself up and going on other VPS providers. Just be sure to install both Docker and docker-compose as we'll be using them throughout this guide.`}</p>
    <p>{`When configuring your server, ignore block storage and choose the region closest to you (`}<a parentName="p" {...{
        "href": "https://cloudharmony.com/speedtest-for-digitalocean:compute"
      }}>{`Cloudharmony`}</a>{` can help you figure out shortest ping times if you want to get super precise). For additional options you can choose to backup your server if you're interested in the additional security and cost, but be certain to choose IPV6 and Monitoring as they're free and easy upgrades that will provide a lot of value. I also recommend adding an SSH key (`}<a parentName="p" {...{
        "href": "https://www.digitalocean.com/community/tutorials/how-to-use-ssh-keys-with-digitalocean-droplets"
      }}>{`Digital Ocean's official docs`}</a>{` explain how to set up your SSH keys really well) but that's up to you. `}</p>
    <h2>{`Domain`}</h2>
    <p>{`For starting up your own server, you'll also need a domain to call your own. Just like email, Mastodon servers identify you by a username at a particular domain. If you don't have one already, I recommend Hover.com (here's my `}<a parentName="p" {...{
        "href": "https://hover.com/ZY6RVTkV"
      }}>{`referral link`}</a>{`) but feel free to use whatever you're comfortable with. Sign up for an account, purchase your domain and go into your domain's DNS settings. For your Mastodon server you'll need two domains:`}</p>
    <ul>
      <li parentName="ul">{`The main domain that you'll access your server from`}<ul parentName="li">
          <li parentName="ul">{`Presumably this will either be at the actual purchased domain aka `}<a parentName="li" {...{
              "href": "https://mymastodonserver.com"
            }}>{`https://mymastodonserver.com`}</a>{` or a subdomain like `}<a parentName="li" {...{
              "href": "https://social.mymastodonserver.com"
            }}>{`https://social.mymastodonserver.com`}</a></li>
        </ul></li>
      <li parentName="ul">{`The access point for Mastodon's streaming server`}<ul parentName="li">
          <li parentName="ul">{`Presumably at `}<a parentName="li" {...{
              "href": "https://streaming.mymastodonserver.com"
            }}>{`https://streaming.mymastodonserver.com`}</a></li>
        </ul></li>
    </ul>
    <p>{`For each of these domains you'll need:`}</p>
    <ul>
      <li parentName="ul">{`A Record pointing to the IPV4 IP Address of your VPS server`}</li>
      <li parentName="ul">{`AAAA Record pointing to the IPV6 IP Address of your VPS server`}</li>
    </ul>
    <p>{`Set those up in your console and we can confirm that this is fully functional once your server is up and running.`}</p>
    <h2>{`Email over SMTP`}</h2>
    <p>{`Mastodon has built in support for SMTP so that you can get emails when you signup and for other activity on your server. This isn't strictly necessary, but is a good practice to do especially if you want to eventually open up your server to others. If you don't have your own SMTP service, I recommend `}<a parentName="p" {...{
        "href": "https://mailgun.com"
      }}>{`Mailgun`}</a>{`. After signing up, they will guide you through setting up your SMTP service and link it with your domain provider so you can send emails from your domain name. If you don't end up using this, I'll point out below where you might do something a bit different.`}</p>
    <h2>{`Setup your VPS`}</h2>
    <p>{`Now we'll actually get going with the Mastodon specific parts of this guide. First we'll SSH into our server and clone down the Mastodon repository from Github.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`ssh`}</span>{` root@`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`your `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ip`}</span>{` address here`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` clone https://github.com/tootsuite/mastodon.git
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`cd`}</span>{` mastodon`}</code></pre></div>
    <p>{`To ensure we're working with stable code, we'll use git to checkout the latest release of the code. As of this writing, the latest release is v1.1.2 but you can find the latest on `}<a parentName="p" {...{
        "href": "https://github.com/tootsuite/mastodon/releases"
      }}>{`Mastodon's release page`}</a>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` checkout v1.5.1`}</code></pre></div>
    <p>{`You can also just run off of the latest code from Github, but be prepared for unstable behavior and contributing back on Github with bug reports and/or code.`}</p>
    <h2>{`Configure Mastodon`}</h2>
    <p>{`The primary method of configuring Mastodon is through environment variables. Thankfully, we can get this done automatically for us via a .env file and Mastodon provides some sane template files for us to go off of. First, we'll copy the existing template to work off of and edit the file. You'll likely have a choice between vim and nano and nano is a great one for beginners`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`cp`}</span>{` .env.production.sample .env.production
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` .env.production`}</code></pre></div>
    <p>{`Let's break down this file into the specific parts that you should edit.`}</p>
    <ol>
      <li parentName="ol">{`Ignore the REDIS and DB env variables`}</li>
    </ol>
    <p>{`These are some relatively sane defaults out of the box so we won't touch the database or Redis configuration for now.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`# Service dependencies
REDIS_HOST=redis
REDIS_PORT=6379
DB_HOST=db
DB_USER=postgres
DB_NAME=postgres
DB_PASS=
DB_PORT=5432`}</code></pre></div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Edit LOCAL_DOMAIN`}</li>
    </ol>
    <p>{`Here we'll fill in the main domain name for your Mastodon server, which will probably be something like `}<a parentName="p" {...{
        "href": "https://mymastodonserver.com"
      }}>{`https://mymastodonserver.com`}</a>{` or `}<a parentName="p" {...{
        "href": "https://social.mymastodonserver.com"
      }}>{`https://social.mymastodonserver.com`}</a></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`# Federation
LOCAL_DOMAIN=<Your domain here>
LOCAL_HTTPS=true`}</code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Generate app secrets`}</li>
    </ol>
    <p>{`We'll generate some secrets so that Mastodon can properly encrypt our communications. Run `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose run --rm web rake secret`}</code>{` three times and fill in each of the application secret fields with a generated secret`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`# Application secrets
PAPERCLIP_SECRET=firstgeneratedsecrethere
SECRET_KEY_BASE=secondgeneratedsecrethere
OTP_SECRET=thirdgeneratedsecrethere`}</code></pre></div>
    <p>{`Follow this with generating your VAPID keys for push notifications using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose run --rm web rake mastodon:webpush:generate_vapid_key`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`VAPID_PRIVATE_KEY=privatekey
VAPID_PUBLIC_KEY=publickey`}</code></pre></div>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Ignore SINGLE_USER_MODE`}</li>
    </ol>
    <p>{`This variable turns your server into Single User Mode which disables signups and causes everyone who visits your site to go automatically to your profile. We'll want this but only after we've created an account.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`# Registrations
# Single user mode will disable registrations and redirect frontpage to the first profile
# We will uncomment this once our user is created
#SINGLE_USER_MODE=true`}</code></pre></div>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Email`}</li>
    </ol>
    <p>{`If you have an SMTP server (such as the Mailgun account you might have created earlier) then fill this out with your account information. If you don't, leave these unchanged.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`# E-mail configuration
# Note: Mailgun and SparkPost (https://sparkpo.st/smtp) each have good free tiers
SMTP_SERVER=smtp.mailgun.org
SMTP_PORT=587
SMTP_LOGIN=<Your Login here>
SMTP_PASSWORD=<Your password here>
SMTP_FROM_ADDRESS=notifications@example.com`}</code></pre></div>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Streaming API Domain`}</li>
    </ol>
    <p>{`Earlier you setup a subdomain for your Mastodon streaming API. Be sure to fill this field in with that domain with the https protocol. I personally encountered issues when I didn't do this on my server but this is theoretically optional.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`STREAMING_API_BASE_URL=https://<your domain here>`}</code></pre></div>
    <p>{`If you are using nano, you can now save and exit the file by typing Ctrl + O to Save and Ctrl + X to exit.`}</p>
    <h2>{`Persist changes in Docker`}</h2>
    <p>{`By default anything in Docker's filesystem that isn't mounted on your main computer is deleted when Docker is stopped. To prevent data loss, uncomment the lines in your docker-compose.yml file that define the volumes for your db and redis containers will use. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'3'`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`

  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`db`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` postgres`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`alpine
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./postgres`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/var/lib/postgresql/data

  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`redis`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` redis`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`alpine
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./redis`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/data`}</code></pre></div>
    <h2>{`Add nginx`}</h2>
    <p>{`By default Mastodon communicates on ports 3000 and 4000, which means that if the server was currently running and we visited our domain we would have to go to `}<a parentName="p" {...{
        "href": "http://mymastodonserver.com:3000"
      }}>{`http://mymastodonserver.com:3000`}</a>{` in order to see anything. That's rather annoying, so we'll use a reverse proxy server to route traffic into each of our containers when we visit the appropriate domain. First we'll setup the container that will do this for us`}</p>
    <p>{`Add the following lines to the end of your docker-compose file`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`nginx-proxy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` jwilder/nginx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`proxy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`latest
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` nginx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`proxy
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"443:443"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"80:80"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /var/run/docker.sock`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/tmp/docker.sock
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /data/certs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/etc/nginx/certs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`ro
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /data/config/nginx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`proxy/html/`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/usr/share/nginx/html
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /data/config/nginx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`proxy/vhost.d/`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/etc/nginx/vhost.d
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /data/config/nginx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`proxy/conf.d/`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/etc/nginx/conf.d
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ENABLE_IPV6=true`}</code></pre></div>
    <p>{`This container will look at the other containers we have setup and utilize environment variables that have been defined to properly determine where to route traffic. We could do that in the .env.production file but I would rather keep all of the configuration for Docker in our docker-compose.yml file. Before that, we'll change the web container to only expose port 3000 instead of opening that port to the outside world.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`web`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` .
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`env_file`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` .env.production
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle exec rails s `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`p 3000 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`b '0.0.0.0'
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`expose`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"3000"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`depends_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` db
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` redis
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./public/assets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/mastodon/public/assets
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./public/packs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/mastodon/public/packs
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./public/system`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/mastodon/public/system`}</code></pre></div>
    <p>{`Then we'll add the environment variables on the web and streaming containers so Nginx knows where the route internet traffic. Add a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`environment`}</code>{` config to each container with the variables `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`VIRTUAL_HOST`}</code>{` defined. In this example we'll also define the optional environment variable `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`VIRTUAL_PORT`}</code>{` that points to the exposed port on each container.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`web`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` .
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`env_file`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` .env.production
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle exec rails s `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`p 3000 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`b '0.0.0.0'
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` VIRTUAL_HOST=<your main mastodon server domain name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` VIRTUAL_PORT=3000
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`expose`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"3000"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`depends_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` db
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` redis
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./public/assets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/mastodon/public/assets
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./public/packs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/mastodon/public/packs
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./public/system`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/mastodon/public/system

  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`streaming`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` .
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`env_file`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` .env.production
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` npm run start
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` VIRTUAL_HOST=<your streaming server domain name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` VIRTUAL_PORT=4000
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"4000:4000"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`depends_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` db
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` redis`}</code></pre></div>
    <p>{`At this point, if we were to launch the servers we would be able to view them at `}<a parentName="p" {...{
        "href": "http://mymastodonserver.com"
      }}>{`http://mymastodonserver.com`}</a>{`. Unfortunately we would not be able to view them using https because we don't have a certificate to encrypt traffic. Since we don't want people spying on our server, we'll do that next.`}</p>
    <h2>{`Add SSL`}</h2>
    <p>{`Let's Encrypt is a free and automatic way to get SSL certificates and just like Nginx there's a container that will handle this automatically for us. Append the following lines to your docker-compose.yml file to create that container.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`letsencrypt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` jrcs/letsencrypt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`nginx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`proxy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`companion
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` letsencrypt
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes_from`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` nginx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`proxy
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /var/run/docker.sock`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/var/run/docker.sock
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /data/certs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/etc/nginx/certs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`rw`}</code></pre></div>
    <p>{`Just like the Nginx container, this utilizes environment variables to know which container is matched to which SSL certificate. Add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`LETSENCRYPT_HOST`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`LETSENCRYPT_EMAIL`}</code>{` variables to our environment block. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`LETSENCRYPT_EMAIL`}</code>{` in this case refers to your personal email.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`web`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` .
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`env_file`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` .env.production
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle exec rails s `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`p 3000 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`b '0.0.0.0'
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` VIRTUAL_HOST=<your main mastodon server domain name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` VIRTUAL_PORT=3000
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` LETSENCRYPT_HOST=<your main mastodon server domain name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` LETSENCRYPT_EMAIL=<your email`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`expose`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"3000"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`depends_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` db
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` redis
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./public/assets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/mastodon/public/assets
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./public/packs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/mastodon/public/packs
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./public/system`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/mastodon/public/system

  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`streaming`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` .
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`env_file`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` .env.production
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` npm run start
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` VIRTUAL_HOST=<your streaming server domain name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` VIRTUAL_PORT=4000
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` LETSENCRYPT_HOST=<your streaming server domain name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` LETSENCRYPT_EMAIL=<your email`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"4000:4000"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`depends_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` db
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` redis`}</code></pre></div>
    <h2>{`Build and start the server`}</h2>
    <p>{`We're almost done!`}</p>
    <p>{`Mastodon requires us to do some final setup that needs to be run each time Mastodon is updated or setup for the first time. We'll setup the database, precompile the assets to be served and actually start our server for the first time.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker-compose run --rm web rails db:migrate
docker-compose run --rm web rails assets:precompile
docker-compose up `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# or docker-compose up --force-recreate if updating Mastodon`}</span></code></pre></div>
    <p>{`This will take some time, but after it finishes you should see a stream of logs from Docker that details the instantiation of each container. Wait about 15 minutes after that starts and you should have access to your Mastodon instance at your domain! Once you've confirmed this works, you can exit the logs by typing Ctrl + C and then run your server in the background by running`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker-compose up -d`}</code></pre></div>
    <p>{`This will prevent your server from being killed whenever you close your terminal.`}</p>
    <p>{`If you have the unfortunate case that your server isn't up and running at your domain, look back over this guide and confirm that all of your configurations are correct. If you don't see what's wrong, it's possible that there's a problem with this guide or it's outdated. Look over the `}<a parentName="p" {...{
        "href": "https://github.com/tootsuite/mastodon#running-with-docker-and-docker-compose"
      }}>{`Official Mastodon docs`}</a>{` and their `}<a parentName="p" {...{
        "href": "https://github.com/tootsuite/documentation/blob/master/Running-Mastodon/Production-guide.md"
      }}>{`Production Guide`}</a>{` and be sure to let me know if you see any problems.`}</p>
    <h2>{`Sign up and make an Admin`}</h2>
    <p>{`Now you can go to your domain and signup for an account. Once you've entered your desired username and password you should receive an email to confirm your account. If you didn't:`}</p>
    <ol>
      <li parentName="ol">{`You didn't setup SMTP`}</li>
      <li parentName="ol">{`SMTP is misconfigured - Check your settings against your SMTP provider and check your logs via `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`docker-compose logs`}</code>{` to see if you can find the error`}</li>
      <li parentName="ol">{`You're using something like Google Cloud Platform that blocks SMTP from going out - check your VPS specific guides on how to fix`}</li>
    </ol>
    <p>{`In order to confirm your user without an email run:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker-compose run web rails mastodon:confirm_email USER_EMAIL`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`your email here`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`To make yourself an admin, run the following command`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker-compose run web rails mastodon:make_admin USERNAME`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`your username`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`Making yourself an admin allows some greater control over your server as well as some additional diagnostics that may help if you're encountering an error.`}</p>
    <h2>{`Single User Mode`}</h2>
    <p>{`Final step is to enable Single User Mode. Modify your .env.production file and uncomment this line to enable it`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`SINGLE_USER_MODE=true`}</code></pre></div>
    <p>{`Now any user that visits your server will be automatically sent to your profile and signups will be prevented. Congratulations! You now have a fully functional server!`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Hopefully this was a helpful guide to getting you setup with your own selfhosted Mastodon instance. You can now enjoy totaly prviacy and control over your own account. If you have any feedback or questions, feel free to ping me at donniewest@social.donniewest.com `}</p>
    <h2>{`Credits`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/tootsuite/documentation"
        }}>{`The official Mastodon docs on Github`}</a><ul parentName="li">
          <li parentName="ul">{`Kudos to Mastodon for the ever-improving documentation`}</li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/nolanlawson/resources-for-mastodon-newbies"
        }}>{`Nolan Lawson's Beginner guide to Mastodon`}</a><ul parentName="li">
          <li parentName="ul">{`This resource guide was indispensable for when I started`}</li>
          <li parentName="ul">{`Nolan Laweson is super helpful to beginners, be sure to toot him`}</li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/ummjackson/mastodon-guide"
        }}>{`Ummjackson's Mastodon Guide`}</a><ul parentName="li">
          <li parentName="ul">{`Awesome general purpose and unopinionated guide for getting a Mastodon server up and running`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      